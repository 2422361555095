import { render, staticRenderFns } from "./ProcessedFabricView.vue?vue&type=template&id=0854b994&scoped=true"
import script from "./ProcessedFabricView.vue?vue&type=script&lang=js"
export * from "./ProcessedFabricView.vue?vue&type=script&lang=js"
import style0 from "./ProcessedFabricView.vue?vue&type=style&index=0&id=0854b994&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0854b994",
  null
  
)

export default component.exports