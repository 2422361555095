<template>
  <div class="card">
    <div class="card-header header-elements-inline bg-secondary">
      <h6 class="card-title"> {{pfab.name}} : Requirements</h6>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">


        <div class="tab-pane fade active show" id="highlighted-tab1" style="background-color: whitesmoke">
          <div class="table-responsive ">
            <table class="table">
              <thead>
              <tr>
                <th>Process</th>
                <th>Article</th>
                <th>Design</th>
                <th>Color</th>
                <th>Quality</th>
                <th>Style</th>
                <th>Size</th>
                <th>Qty</th>
                <th>#</th>
              </tr>
              </thead>
              <tbody v-model="pfab" v-for="(detail, index) in pfab.inputs" >
                <tr style="padding: 0px;">
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="detail"  v-model="detail.item.process.code" >
                      <option v-for="process in processes" v-bind:value="process.code">
                        {{ process.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="detail"  v-model="detail.item.article.code">
                      <option v-for="group in groups" v-bind:value="group.code">
                        {{ group.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="detail" v-model="detail.item.design.code">
                      <option value="1">-</option>
                      <option v-for="design in designs" v-bind:value="design.code">
                        {{ design.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="detail" v-model="detail.item.color.code">
                      <option value="1">-</option>
                      <option v-for="color in colors" v-bind:value="color.code">
                        {{ color.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="detail" v-model="detail.item.quality.code">
                      <option value="1">-</option>
                      <option v-for="quality in qualities" v-bind:value="quality.code">
                        {{ quality.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="detail" v-model="detail.item.style.code">
                      <option value="1">-</option>
                      <option v-for="style in styles" v-bind:value="style.code">
                        {{ style.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="detail" v-model="detail.item.size.code">
                      <option v-for="size in sizes" v-bind:value="size.code">
                        {{ size.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <input type="number" class="form-control text-right" placeholder="Qty required" min="0" v-model="detail.qty">
                  </td>
                  <td style="padding: 0px;text-align: center;">
                    <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeFabricRow(index)" tabindex="-1" disabled><i class="icon-eraser"></i></button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <td style="padding: 0px;">
                  <button type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addFabricRow" disabled>
                    <i class="icon-plus3"></i>
                  </button>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tfoot>
            </table>
          </div>
        </div>

      <!--</div>-->
    </div>
    <div class="card-footer" style="padding: 3px;padding-right: 18px;">
      <div class="text-right" >
        <button type="button" class="btn btn-outline-primary" @click="saveDocument" disabled >Update<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'FabricRequirementForm',
    data () {
      return {
        pfab: JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","parent":0,"root":0,"process":{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}},"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"inputs":[]}'),
        fabrics: [],
        processes:[],
        groups:[],
        designs:[],
        colors:[],
        qualities:[],
        styles:[],
        sizes:[],
      }
    },
    props: {
      myfabric: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","parent":0,"root":0,"process":{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}},"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"inputs":[]}')
      }
    },
    beforeMount () {
      this.pfab = this.myfabric; // save props data to itself's data and deal with tms

      if( this.pfab.inputs.length == 0 ){
        this.addFabricRow();
      }
    },
    component: {

    },
    created () {

    },
    mounted () {
      let self = this;

      // self.loadFabrics();
      self.loadAll();
    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeFabReqModal();
      },
      clear(){
        this.$data.pfab = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","parent":0,"root":0,"process":{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}},"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"inputs":[]}');
      },
      addFabricRow(){

        let raw = {"item":{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","parent":0,"process":{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}},"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0},"qty":0,"wgt":0};

        raw.item.design.code = this.$data.pfab.design.code;
        raw.item.color.code = this.$data.pfab.color.code;
        raw.item.quality.code = this.$data.pfab.quality.code;
        raw.item.style.code = this.$data.pfab.style.code;
        raw.item.size.code = this.$data.pfab.size.code;
        this.$data.pfab.inputs.push (raw);
      },
      removeFabricRow(idx){
        if (idx > -1) {
          this.$data.pfab.inputs.splice(idx, 1);
        }
      },
      loadAll(){
        this.loadFabrics();
        this.loadProcesses();
        this.loadDesigns();
        this.loadColors();
        this.loadItemGroups();
        this.loadQualities();
        this.loadStyles();
        this.loadSizes();
      },
      loadFabrics(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };
        self.$data.fabrics = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/fabric/all/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != [] ){
              self.$data.fabrics = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadProcesses(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/nature/2/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.processes = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadItemGroups(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/itemgroup/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.groups = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadColors(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/color/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.colors = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadDesigns(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/design/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.designs = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadQualities(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/quality/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.qualities = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadStyles(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/style/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.styles = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadSizes(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/size/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.sizes = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      saveDocument(){
        let self = this;


        if(self.$data.pfab.code < 1){
          alert("Invalid Fabric");
          return
        }

        self.pfab.inputs.forEach(function (fab) {

          fab.fabric.design.code = parseInt(fab.fabric.design.code)
          fab.fabric.color.code = parseInt(fab.fabric.color.code)
          fab.fabric.quality.code = parseInt(fab.fabric.quality.code)
          fab.fabric.style.code = parseInt(fab.fabric.style.code)
          fab.weight =  parseFloat(fab.weight);
          fab.qty =  parseFloat(fab.qty);
        });


        const requestOptions = {
          method:  'PUT',
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.pfab)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/pfab/fabricrequirement/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.pfab = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","parent":0,"root":0,"process":{"id":"","code":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":"","code":0,"type":0,"name":"","alias":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":""}}},"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"inputs":[]}');
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
